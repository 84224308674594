<template>
  <div class="checkCmpt">
    <div style="position: absolute;right:20px;top:125px;z-index: 999;">
      <el-tooltip placement="top">
        <div slot="content">
          1.在货主核对发票阶段，等您将相关发票电子文件上传<br />2.您可以清楚的看到进度状态<br />3.每一批次运单的右侧有“继续”按钮，点击即可跳转
        </div>
        <div style="margin: 0px 20px;color: #0F5FFF;">
          <i class="el-icon-question"></i>
          <span>帮助</span>
        </div>
      </el-tooltip>
    </div>
    <!-- 搜索框 -->
    <el-form ref="searchForm" inline :model="searchForm" size="medium" label-width="120px">
      <el-form-item label="开票申请编号" prop="invoiceTaskID">
        <el-input v-model="searchForm.invoiceTaskID" placeholder="请输入开票申请编号" clearable style="width: 180px;"></el-input>
      </el-form-item>
      <el-form-item label="业务类型" prop="businessType">
        <el-select v-model="searchForm.businessType" placeholder="请选择业务类型" @change="businessChange" clearable>
          <el-option v-for="item in businessList" :key="item.Code" :label="item.Name" :value="item.Code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="场景类型" prop="sceneType">
        <el-select v-model="searchForm.sceneType" placeholder="请选择场景类型" clearable>
          <el-option v-for="item in sceneList" :key="item.Code" :label="item.Name" :value="item.Code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发票类型" prop="invoiceType">
        <el-select v-model="searchForm.invoiceType" placeholder="请选择发票类型" clearable>
          <el-option v-for="item in invoiceList" :key="item.Code" :label="item.Name" :value="item.Code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="支付方式" prop="payType">
        <el-select v-model="searchForm.payType" placeholder="请选择支付方式">
          <el-option v-for="item in payList" :key="item.Code" :label="item.Name" :value="item.Code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="货主是否已支付" prop="payStatus">
        <el-select v-model="searchForm.payStatus" placeholder="请选择服务商是否已开票" clearable>
          <el-option label="全部" value=""></el-option>
          <el-option label="未支付" value="0"></el-option>
          <el-option label="已支付" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否已开票" prop="taskStatus">
        <el-select v-model="searchForm.taskStatus" placeholder="请选择服务商是否已开票" clearable>
          <el-option label="全部" value=""></el-option>
          <el-option label="未开票" value="0"></el-option>
          <el-option label="已开票" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="keyword" style="margin-left: 10px;">
        <div style="display: flex;">
          <el-dropdown @command="chooseDeteType" trigger="click" placement="bottom">
            <span class="el-dropdown-link">
              <div
                style="width: 98px; text-align: right;padding-right: 12px; color: #606266; cursor: pointer;white-space: nowrap;">
                {{ searchForm.deteTypeName }}<i style="margin-left: 2px;" class="el-icon-arrow-down"></i></div>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="item" v-for="item in deteTypeList" :key="item.id">{{ item.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-date-picker v-model="searchForm.time" type="datetimerange" start-placeholder="请选择开始时间"
            end-placeholder="请选择结束时间" value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </div>
      </el-form-item>
      <el-button style="margin-left:1%;" type="primary" size="medium" icon="el-icon-search" @click="search">搜索
      </el-button>
      <el-button type="primary" size="medium" icon="el-icon-delete" @click="resetForm()" style="margin-bottom: 16px;">清空
      </el-button>
      <el-button type="primary" size="medium" icon="el-icon-download" @click="uploadClick()"
        :disabled="!tableData.length">下载税务表格</el-button>
    </el-form>
    <!--数量金额总计-->
    <div style="margin: 10px 30px;display: flex;align-items: center;" v-if="!flag.tableDataLoading">
      <div style="display:flex;align-items: center; flex-direction: column; margin-bottom: 10px;color:#333">
        <div style="color:rgb(255, 139, 23)"><span class="money-label">含税金额总计：</span> <span class="money-txt">￥{{
         PendProgress.TotalNeedPayMoneySurplus
        }}</span></div>
      </div>
      <!-- <div style="display:flex;align-items: center; flex-direction: column; margin-bottom: 10px;color:#333">
        <div> <span class="money-label">运费总计：</span> <span class="money-txt">/</span>
        </div>
        <div style="margin: 15px 0px">
          <span class="money-label">剩余运费总计：</span> <span class="money-txt">/</span>
        </div>
        <div style="color:rgb(255, 139, 23)"><span class="money-label">已退运费总计：</span><span class="money-txt">/</span>
        </div>
      </div>
      <div style="display:flex;align-items: center;flex-direction: column;margin-bottom: 10px;color:#333">
        <div><span class="money-label">服务费/税费总计：</span> <span class="money-txt">￥{{
          PendProgress.TotalExpensesOfTaxation
        }}</span></div>
        <div style="margin: 15px 0px;">
          <span class="money-label">剩余服务费/税费总计：</span> <span class="money-txt">￥{{
            PendProgress.TotalExpensesOfTaxationSurplus }}</span>
        </div>
        <div style="color:rgb(255, 139, 23)"><span class="money-label">已退服务费/税费总计：</span> <span class="money-txt">￥{{
          PendProgress.TotalExpensesOfTaxationBack }}</span></div>
      </div>
      <div style="display:flex;align-items: center;flex-direction: column;color:#333">
        <div><span class="money-label">运单总金额总计：</span> <span class="money-txt">￥{{ PendProgress.TotalNeedPayMoney
        }}</span>
        </div>
        <div style="margin: 15px 0px">
          <span class="money-label">剩余运单总金额总计：</span> <span class="money-txt">￥{{
            PendProgress.TotalNeedPayMoneySurplus
          }}</span>
        </div>
        <div style="color:rgb(255, 139, 23)"><span class="money-label">已退运单总金额总计：</span><span class="money-txt">￥{{
          PendProgress.TotalNeedPayMoneyBack
        }}</span></div>
      </div> -->
    </div>
    <!-- 主体区域 -->
    <div class="bodyArea">
      <!-- 表格数据 -->
      <el-table :data="tableData" v-loading="flag.tableDataLoading"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }">
        <el-table-column fixed align="center" prop="index" label="序号" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="InvoiceTaskID" label="开票申请编号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceType" label="发票类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="billType" label="业务类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayModeString" label="支付方式" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DemanderName" label="货主单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="SupplierName" label="服务商单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" label="是否已支付" width="200">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.PayStatus == 0" type="danger">未支付</el-tag>
            <el-tag v-if="scope.row.PayStatus == 1" type="success">已支付</el-tag>
            <el-tag v-if="scope.row.PayStatus == 4" type="warning">支付中</el-tag>
            <el-tag v-if="scope.row.PayStatus == 2" type="warning">货主放弃付款中</el-tag>
            <el-tag v-if="scope.row.PayStatus == 3" type="info">已放弃付款</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="是否已开票" width="200">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.Disallowance == 1 && scope.row.InvoicePrintDatetime" type="danger">重新开票</el-tag>
            <el-tag v-if="scope.row.Disallowance == 0 && scope.row.InvoicePrintDatetime" type="success">已开票</el-tag>
            <el-tag v-if="scope.row.Disallowance == 0 && !scope.row.InvoicePrintDatetime" type="danger">未开票</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ApplyDatetime" label="开票申请时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayDatetime" label="确认支付时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoicePrintDatetime" label="开票完成日期" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="WaybillCounts" label="总计运单数量" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" label="费率" width="200">
          <template slot-scope="scope">
            <p>{{ scope.row.Rate }}%</p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TransportCosts" label="运费" width="200">
          <template slot-scope="scope">
            <div style="color:#333;">/</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TransportCosts" label="剩余运费" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">/</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TransportCosts" label="已退运费" width="200">
          <template slot-scope="scope">
            <div style="color:#333;">/</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ExpensesOfTaxation" label="服务费/税费" width="200">
          <template slot-scope="scope">
            <div style="color:#333;">
              {{ scope.row.ExpensesOfTaxation | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TransportCosts" label="剩余服务费/税费" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.ExpensesOfTaxationSurplus | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TransportCosts" label="已退服务费/税费" width="200">
          <template slot-scope="scope">
            <div style="color:#333;">
              {{ scope.row.ExpensesOfTaxationBack | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="NeedPayMoney" label="运单总金额" width="200">
          <template slot-scope="scope">
            <div style="color:#333;">
              {{ scope.row.NeedPayMoney | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TransportCosts" label="剩余运单总金额" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.NeedPayMoneySurplus | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TransportCosts" label="已退运单总金额" width="200">
          <template slot-scope="scope">
            <div style="color:#333;">
              {{ scope.row.NeedPayMoneyBack | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="300">
          <template slot-scope="scope">
            <el-button v-if="scope.row.PayStatus == 1" type="primary" size="small" @click="toRefund(scope.row)"
              :loading="flag.refundbillLoading">退款
            </el-button>
            <el-button v-if="scope.row.TaskStatus == 5" type="info" size="small">放弃开票中</el-button>
            <el-button v-if="scope.row.PayStatus == 2 && scope.row.TaskStatus != 5" @click="giveUpInvoice(scope.row)"
              type="primary" size="small">货主放弃付款</el-button>
            <el-button v-if="scope.row.Disallowance == 0 && scope.row.PayStatus != 2 && scope.row.TaskStatus != 5"
              @click="goOn(scope.row)" type="primary" size="small">{{ scope.row.TaskStatus == 7 ? '继续（已暂存）' : '继续' }}
            </el-button>
            <el-button v-if="scope.row.Disallowance == 1 && scope.row.PayStatus != 2 && scope.row.TaskStatus != 5"
              @click="goUpdate(scope.row)" type="warning" size="small">修改</el-button>
            <el-button type="primary" size="small" @click="openVoucherDialog(scope.row)" title="凭证">凭证</el-button>

          </template>
        </el-table-column>
      </el-table>
      <div style="display:flex; align-items:center;">
        <!-- 分页 -->
        <el-pagination background class="pagination" style="margin-top:10px" @current-change="handleCurrentChange"
          :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper"
          :total="pagination.total">
        </el-pagination>
      </div>
      <!-- 返回 -->
      <div style="margin-top:10px;display:flex;justify-content:center">
        <el-button @click="goBack" icon="el-icon-arrow-left">返回</el-button>
      </div>
      <!-- 服务商同意货主放弃付款 -->
      <el-dialog :visible.sync="flag.showGiveUpInvoice" width="700px" center>
        <p style="margin: 8px 0">1.货主选择了【放弃付款】，并向您说了声“对不起”。本结算单将终止流程。</p>
        <p style="margin: 8px 0">2.您的每月最大可开票额度会自动补充。</p>
        <div style="text-align: center;">
          <el-button type="primary" size="mini" @click="handleGiveUpInvoice">同意</el-button>
        </div>
      </el-dialog>
      <!-- 退款运单dialog -->
      <el-dialog width="1200px" :visible.sync="flag.refundbillDialog" append-to-body>
        <div style="display:flex;align-items:center">
          <el-form ref="refundParmas" inline :model="refundParmas" size="mini">
            <el-form-item label="司机姓名" prop="DriverName">
              <el-input v-model="refundParmas.DriverName" placeholder="请输入司机姓名" style="width:230px"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="DriverPhone">
              <el-input v-model="refundParmas.DriverPhone" placeholder="请输入手机号" style="width:230px"></el-input>
            </el-form-item>
            <el-form-item label="身份证号" prop="DriverIdCard">
              <el-input v-model="refundParmas.DriverIdCard" placeholder="请输入身份证号" style="width:230px"></el-input>
            </el-form-item>
            <el-form-item label="客户单号" prop="CarriageBillID">
              <el-input v-model="refundParmas.CarriageBillID" placeholder="请输入客户单号" style="width:230px"></el-input>
            </el-form-item>
            <el-form-item label="运单号" prop="WaybillID">
              <el-input v-model="refundParmas.WaybillID" placeholder="请输入运单号" style="width:230px"></el-input>
            </el-form-item>
            <el-button type="primary" size="mini" @click="toRefund(currentItem)">搜索
            </el-button>
            <el-button type="primary" size="mini" @click="$refs['refundParmas'].resetFields()">
              清除
            </el-button>
            <el-button type="primary" size="mini" @click="openRemarkDialog" :disabled="!manuallist.length">
              勾选运单退款
            </el-button>
            <el-button type="primary" size="mini" @click="submitRefund(2)" :disabled="IsChargeback">整单退款
            </el-button>
          </el-form>
          <!-- 下载按钮 -->
        </div>
        <!-- 表格信息 -->
        <el-table style="width: 100%;overflow: auto;" height='500' :data="refundBillsData"
          v-loading="flag.refundbillLoading" @select="selectChange" @select-all="selectChange" ref="allWaybillRef"
          :header-cell-style="{ color: '#666', background: '#f0f0f0' }">
          <el-table-column type="selection" width="55" :selectable="selectEnable"></el-table-column>
          <el-table-column fixed align="center" prop="index" label="序号" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="WaybillNumber" label="运单号" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="CarriageBillID" label="客户单号" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="InvoiceType" label="发票类型" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="waybillType" label="业务类型" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="Shipper_name" label="货主单位" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="DriverName" label="司机姓名" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="Phone" label="司机手机号码" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="DriverIDCard" label="司机身份证号码" width="200"></el-table-column>
          <el-table-column align="center" prop="PayName" label="收款人姓名" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="Recy_payment_idcard" label="收款人手机号码" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="PayeeIDCard" label="收款人身份证号码" width="200"></el-table-column>
          <el-table-column align="center" prop="LeaderName" label="车队长姓名" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="LeaderPhone" label="车队长手机号码" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="LeaderIDCard" label="车队长身份证号码" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="LicensePlate" label="车牌" width="200"></el-table-column>
          <el-table-column align="center" prop="Net_weight" label="装货净重(吨)" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="Product_name" label="货品名称" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="Product_unit" label="货品单位" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="Loading_time" label="装货时间" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="Submission_time" label="签收时间" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="Start_addrees" label="起始地" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="Arrivals" label="到达地" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="Number_allocated_drivers" label="司机装货数量" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="Driver_transportation_price" label="司机运输单价（人民币）" width="200">
            <template slot-scope="scope">
              <div style="color:#FF8B17;">
                {{ scope.row.Driver_transportation_price | formatMoney }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="amount_count" label="运费" width="200">
            <template slot-scope="scope">
              <div style="color:#FF8B17;">
                /
                <!-- {{ scope.row.amount_count | formatMoney }} -->
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="ExpensesOfTaxation" label="服务费/税费" width="200">
            <template slot-scope="scope">
              <div style="color:#FF8B17;">
                {{ scope.row.ExpensesOfTaxation | formatMoney }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="TotalAmountIncludeTaxes" label="运单总金额" width="200">
            <template slot-scope="scope">
              <div style="color:#FF8B17;">
                {{ scope.row.TotalAmountIncludeTaxes | formatMoney }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="Entrusted_Collection_Amount" label="委托代开运费" width="200"
            v-if="$entrustSceneIds.includes(Number(currentItem.TaskSceneTypeID))">
            <template slot-scope="scope">
              <div style="color:#FF8B17;">
                /
                <!-- {{ scope.row.Entrusted_Collection_Amount | formatMoney }} -->
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="TaxesDZ" label="委托代开服务费/税费" width="200"
            v-if="$entrustSceneIds.includes(Number(currentItem.TaskSceneTypeID))">
            <template slot-scope="scope">
              <div style="color:#FF8B17;">
                {{ scope.row.TaxesDZ | formatMoney }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="AmountDZ" label="委托代开运单总金额" width="200"
            v-if="$entrustSceneIds.includes(Number(currentItem.TaskSceneTypeID))">
            <template slot-scope="scope">
              <div style="color:#FF8B17;">
                {{ scope.row.AmountDZ | formatMoney }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="Unloading_net_weight" label="卸货净重(吨)" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="RiskPath" label="风控路径" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="PayMethod" label="支付方式" width="200" show-overflow-tooltip>
          </el-table-column>
          <!-- <el-table-column align="center" prop="InvoiceAmountType" label="开票金额" width="200" show-overflow-tooltip>
          </el-table-column> -->
          <el-table-column align="center" prop="SourceBillId" label="溯源运单号" width="200" show-overflow-tooltip>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination background style="margin-top: 16px;" @current-change="pagination1Change"
          :current-page.sync="pagination1.page" :page-size="pagination1.pagesize"
          :page-sizes="[10, 20, 30, 40, 50, 100, 500, 1000]" @size-change="pagination1SizeChange"
          layout="total, sizes,prev, pager, next, jumper" :total="pagination1.total">
        </el-pagination>
      </el-dialog>
      <!-- 勾选退单备注dialog -->
      <el-dialog width="600px" :visible.sync="flag.remarkDialog" append-to-body>
        <el-input type="textarea" autosize placeholder="请输入退款备注" maxlength="400" v-model="remark"></el-input>
        <div style="text-align:center; margin-top:16px;">
          <el-button style="margin-right: 16px;" type="primary" size="mini" @click="submitRefund(1)">确认</el-button>
          <el-button type="primary" size="mini" @click="flag.remarkDialog = false">取消</el-button>
        </div>
      </el-dialog>
    </div>

    <!-- 上传凭证dialog -->
    <el-dialog width="1200px" title="凭证" center :visible.sync="flag.voucherDialog" append-to-body
      v-if="flag.voucherDialog" :close-on-click-modal="false">
      <EvidenceInfo :businessID="currentInvoiceTaskItem.InvoiceTaskID">
      </EvidenceInfo>
    </el-dialog>
  </div>
</template>

<script>
import { giveUpInvoice, pendingList, DownSupplierInvoiceWaybills, Refund, RefundWaybill } from '@/api/waybill/pendingManage/index'
import { mapGetters, mapMutations } from 'vuex'
import { getDataDict } from '@/api/common/common'
import { getTaskScene } from '@/api/goodsOwner/goodsOwnerManage/basicInfo'
import { billsInfoDeatils } from '@/api/supplierWaybill/basicWaybill/makeInvoice'
import EvidenceInfo from "@/components/businessCmpt/evidenceInfo";
export default {
  props: {
    invoiceTaskID: '',
    enterpriseFullName: '', //货主单位/企业名称
  },
  data() {
    return {
      flag: {
        tableDataLoading: false, //是否正在加载表格
        showGiveUpInvoice: false, //同意货主放弃付款dialog
        refundbillDialog: false, // 退款运单弹窗
        refundbillLoading: false, // 退款运单loading
        remarkDialog: false, // 勾选退款备注弹窗
        voucherDialog: false, //凭证dialog
      },
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      pagination1: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      refundParmas: {
        DriverPhone: '',
        DriverName: '',
        DriverIdCard: '',
        WaybillID: '',
        CarriageBillID: ''
      },
      refundBillsData: [],
      remark: '',  // 退款备注
      manuallist: [], // 选中的数据
      IsChargeback: false, // 是否有退款的单
      currentItem: {},
      tableData: [],
      PendProgress: {}, // 获取的数据对象
      currentTaskId: null, //当前正在操作的开票单号
      searchForm: {
        invoiceTaskID: '', //结算任务单号
        invoiceType: '', //发票类型
        payType: 0, // 支付方式
        businessType: '', //业务类型
        sceneType: '', //场景类型
        payStatus: '', //是否已支付
        taskStatus: '', //是否已开票
        time: ['', ''], //时间筛选
        deteType: 3, //时间类型
        deteTypeName: '支付时间', //时间类型

      },
      deteTypeList: [{
        id: 0,
        name: '开票申请时间',
      }, {
        id: 1,
        name: '发票上传时间',
      },
      // {
      //   id: 2,
      //   name: '货主收票时间',
      // },
      {
        id: 3,
        name: '支付时间',
      }
        // , {
        //   id: 4,
        //   name: '导入运单时间',
        // }
      ], //时间类型
      sceneList: [], //场景类型
      businessList: [], //业务类型
      invoiceList: [], //发票类型
      payList: [{ Name: '全部', Code: 0 }, { Name: '线下支付', Code: 1 }, { Name: '线上支付', Code: 2 }], //支付方式
      currentInvoiceTaskItem: {},
    }
  },
  methods: {
    ...mapMutations(['setInvoiceTaskID']),
    init() {
      //获取发票类型
      getDataDict({ type: 20 }).then(res => {
        this.invoiceList = res.patterSetInfo
      })
      //获取业务类型
      getDataDict({ type: 27 }).then(res => {
        this.businessList = res.patterSetInfo
      })
    },
    goBack() {
      this.$router.push('/commission/pendingManage/index')
    },
    //业务类型改变
    businessChange(e) {
      this.searchForm.sceneType = ''
      getTaskScene({ TaskTypeID: e }).then(res => {
        this.sceneList = res.TaskTypeList.map(item => {
          return {
            Code: item.TaskSceneTypeID,
            Name: item.TaskSceneName,
          }
        })
      })
    },
    //选择时间类型
    chooseDeteType(e) {
      this.searchForm.deteType = e.id
      this.searchForm.deteTypeName = e.name
    },
    //搜索
    search() {
      this.pagination.page = 1
      this.pendingList()
    },
    // 清空
    resetForm() {
      this.flag.tableDataLoading = true
      this.pagination = {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      }
      this.searchForm = {
        invoiceTaskID: '', //结算任务单号
        invoiceType: '', //发票类型
        payType: 0, // 支付方式
        businessType: '', //业务类型
        sceneType: '', //场景类型
        payStatus: '', //是否已支付
        taskStatus: '', //是否已开票
        time: ['', ''], //时间筛选
        deteType: 3, //时间类型
        deteTypeName: '支付时间', //时间类型
      }
      this.pendingList()
    },
    // 下载税务表格
    uploadClick() {
      const loading = this.$loading({
        lock: true,
        text: '下载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if (!this.searchForm.time) this.searchForm.time = ["", ""]
      let params = {
        invoiceTaskID: this.getInvoiceTaskID,
        OptionType: 1, //0货主 1服务商
        PayStatus: this.searchForm.payStatus, //支付状态
        taskStatus: this.searchForm.taskStatus, //是否已开票
        StartDateTime: this.searchForm.time[0],
        EndDateTime: this.searchForm.time[1],
        DateType: this.searchForm.deteType, //时间类型
        InvoiceType: this.searchForm.invoiceType, //发票类型
        payModeId: this.searchForm.payType == 0 ? [] : this.searchForm.payType == 1 ? ['1', '4'] : ['2', '3'], // 支付方式 
        SceneType: this.searchForm.sceneType, //场景类型
        TaskType: this.searchForm.businessType, //业务类型
        Consignor: this.searchForm.consignor, //服务商名称
        InvoiceTaskID: this.searchForm.invoiceTaskID, //结算单号
        ownerEnterpriseName: this.enterpriseFullName, //货主单位
        PageIndex: this.pagination.page,
        PageSize: this.pagination.pagesize,
      }
      DownSupplierInvoiceWaybills(params, {
        responseType: "blob",
      }).catch((err) => {
        let { data } = err;
        let that = this;
        if (!data.type.includes("zip")) {
          let reader = new FileReader();
          reader.readAsText(data);
          reader.onload = function (e) {
            const { msg, title } = JSON.parse(reader.result);
            that.$message({
              message: `下载失败 ${msg || title}`,
              type: "error",
            });
            loading.close();
          };
          return;
        }
        const blob = new Blob([data]);
        let fileName = err.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]; //拆解获取文件名，
        if ("download" in document.createElement("a")) {
          // 非IE下载
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
        loading.close();
      });
    },
    //继续
    goOn(item) {
      this.setInvoiceTaskID(item.InvoiceTaskID)
      this.$router.push({ path: '/commission/makeInvoice/makeInvoice', query: { step: 4, currentStep: 6, enterpriseFullName: this.enterpriseFullName } })
    },
    //去修改发票页面
    goUpdate(item) {
      this.setInvoiceTaskID(item.InvoiceTaskID)
      this.$router.push({ path: '/commission/makeInvoice/updateInvoice', query: { step: 4, currentStep: 6, enterpriseFullName: this.enterpriseFullName } })
    },
    //分页回调
    handleCurrentChange(e) {
      this.pagination.page = e
      this.pendingList()
    },
    //获取待办列表
    pendingList() {
      this.flag.tableDataLoading = true
      if (!this.searchForm.time) this.searchForm.time = ["", ""]
      let params = {
        invoiceTaskID: this.getInvoiceTaskID,
        OptionType: 1, //0货主 1服务商
        PayStatus: this.searchForm.payStatus, //支付状态
        taskStatus: this.searchForm.taskStatus, //是否已开票
        StartDateTime: this.searchForm.time[0],
        EndDateTime: this.searchForm.time[1],
        DateType: this.searchForm.deteType, //时间类型
        InvoiceType: this.searchForm.invoiceType, //发票类型
        payModeId: this.searchForm.payType == 0 ? [] : this.searchForm.payType == 1 ? ['1', '4'] : ['2', '3'], // 支付方式 
        SceneType: this.searchForm.sceneType, //场景类型
        TaskType: this.searchForm.businessType, //业务类型
        Consignor: this.searchForm.consignor || '', //服务商名称
        InvoiceTaskID: this.searchForm.invoiceTaskID, //结算单号
        ownerEnterpriseName: this.enterpriseFullName, //货主单位
        PageIndex: this.pagination.page,
        PageSize: this.pagination.pagesize,
      }
      pendingList(params).then(res => {
        this.PendProgress = res.PendProgress || {}
        this.tableData = this.PendProgress.pendList.map((item, index) => {
          item.index = index + 1
          return item
        })
        this.pagination.total = Number(this.PendProgress.count)
      }).finally(() => {
        this.flag.tableDataLoading = false
      })
    },
    //处理同意货主放弃付款
    handleGiveUpInvoice() {
      const params = {
        taskId: this.currentTaskId
      }
      giveUpInvoice(params).then(res => {
        this.flag.showGiveUpInvoice = false
        this.pendingList()
      })
    },
    //同意货主放弃付款面板
    giveUpInvoice(item) {
      this.currentTaskId = item.InvoiceTaskID
      this.flag.showGiveUpInvoice = true
    },
    // 点击凭证， 打开父组件中的dialog
    uploadVoucher(flag, data) {
      this.setInvoiceTaskID(data.InvoiceTaskID)
      this.$emit('uploadVoucher', flag, data)
    },
    // 已选中数据
    selectChange(val) {
      this.manuallist = val;
    },
    openRemarkDialog() {
      this.remark = ''
      this.flag.remarkDialog = true
    },
    selectEnable(row, rowIndex) {
      if (row.IsChargeback) {
        this.IsChargeback = true
      }
      return !row.IsChargeback;
    },
    // 退款获取运单号
    toRefund(row) {
      this.remark = ''
      this.manuallist = [];
      this.currentItem = row
      this.flag.refundbillLoading = true
      this.IsChargeback = false
      let params = {
        InvoiceTaskID: row.InvoiceTaskID,
        PageSize: this.pagination1.pagesize,
        PageIndex: this.pagination1.page,
        ...this.refundParmas
      }
      billsInfoDeatils({ json: JSON.stringify(params) }).then(res => {
        this.flag.refundbillDialog = true
        this.pagination1.total = Number(res.totalRowCount)
        this.refundBillsData = res.invoices.map((item, index) => {
          item.facaValue = 0
          item.index = index + 1
          return item
        })
      }).finally(() => {
        this.flag.refundbillLoading = false
      })
    },
    // 退款
    submitRefund(type) {
      this.flag.remarkDialog = false
      this.$confirm('确定要退款吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '退款中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        (type == 1 ? RefundWaybill : Refund)({
          invoiceTaskID: this.currentItem.InvoiceTaskID,
          ...(type == 1 ? {
            waybillIds: this.manuallist.map(it => { return it.WaybillNumber }),
            remark: this.remark
          } : {})
        }).then(res => {
          this.flag.refundbillDialog = false
          this.$message.success("退款成功")
          if (type == 1) {
            this.toRefund(this.currentItem)
          }
          this.IsChargeback = false
          this.pendingList()
        }).finally(() => {
          loading.close()
        })
      })
    },
    pagination1Change(e) {
      this.pagination1.page = e;
      this.toRefund(this.currentItem);
    },
    pagination1SizeChange(e) {
      this.pagination1.pagesize = e;
      this.toRefund(this.currentItem);
    },
    //打开上传凭证弹窗
    openVoucherDialog(item) {
      this.flag.voucherDialog = true;
      this.currentInvoiceTaskItem = item;

    },
  },
  computed: {
    ...mapGetters(['getInvoiceTaskID'])
  },
  created() {
    this.pendingList()
    this.init()
  },
  components: {
    EvidenceInfo,
  },
}
</script>
<style lang="scss">
.checkCmpt {
  .bodyArea {
    .pagination {
      margin-top: 10px;
    }
  }

  .el-select {
    .el-input__inner {
      width: 180px;
    }
  }
}

.money-label {
  display: inline-block;
  width: 150px;
  text-align: right;
}

.money-txt {
  display: inline-block;
  width: 200px;
  text-align: left;
}
</style>